<template>
  <div>
    <TopNavigation />
    <div align="center" justify="center" class="py-10">
      <h2 class="aboutTitle justify-center">Sustainable Food Products</h2>
      <h3 class="aboutSubTitle">For a healthy future</h3>
      <!-- <span class="aboutMessage">Available only in Hyderabad</span> -->
    </div>
  </div>
</template>
<script>
import TopNavigation from './TopNavigation.vue'
export default {
  name: 'AboutUs',
  components: {
    TopNavigation
  },
  data () {
    return {
    }
  },
  methods: {

  },
  mounted () {

  }
}
</script>
<style scoped>
.aboutTitle {
  color: #fdfaf1;
  font-size: 30px;
}
.aboutSubTitle {
  color: #fdfaf1;
  font-size: 20px;
}
.aboutMessage {
  color: #fdfaf1;
  font-size: 14px;
}
</style>
